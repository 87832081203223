<template>
    <div>
        <Navigation />

        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>
                <img src="@/assets/wordOp/wronglyTitle.png" alt="" class="title">
                <div class="ct_box">
                    <div class="form_box">
                        <div class="upload_box">
                            <el-upload accept="pdf,docx" :show-file-list="true" :on-success="handleAvatarSuccess"
                                @on-error="err" :limit="1" class="upload-demo" drag action="/api/common/upload"
                                :data=formData :headers="headerObj" :multiple="false">
                                <div class="el-upload__text">
                                    <!-- <p class="text">将文件拖拽到此区域上传</p> -->
                                    <img src="@/assets/wordOp/upload.png" alt="" style="margin:1rem 0;">
                                    <div class="el-upload__tip" slot="tip">仅支持.docx 此类文件</div>
                                </div>
                                <!-- <i class="el-icon-upload"></i> -->
                                <div class=""></div>
                                <!-- <em>点击上传</em> -->
                            </el-upload>
                        </div>
                        <button class="submit" @click="file_data">生成内容</button>
                        <el-input resize="none" readonly type="textarea" :rows="13" v-model="generatedContent">
                        </el-input>
                        <button class="copy" @click="copy()">复制内容</button>
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />
    </div>
</template>

<script>
import wordNav from '../../components/wordNav.vue'
export default {
    name: '',
    created() { },
    mounted() {
        this.getUserInfo()
    },
    components: {
        wordNav
    },
    data() {
        return {
            //type: '', // 行业
            //business: '',// 擅长领域
            //personal: '',// 个人荣誉
            keyword: '', // 关键词
            headerObj: {
                'token': this.$user_info.token
            },
            // 请求参数
            formData: {
                type: 'read'
            },
            fileList: [],
            generatedContent: '',
            fullscreenLoading: false,
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }
        },
        copy() {
            //参数是复制的内容
            //.then()中的函数是复制成功和复制失败的两个回调函数
            this.$copyText(this.generatedContent).then(
                (e) => {

                    return this.$message({
                        message: '复制成功',
                        type: 'success',
                        offset: 80
                    });
                },
                (err) => {
                    return this.$message({
                        message: '复制失败',
                        type: 'warning',
                        offset: 80
                    });
                }
            );
        },
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading = true
            console.log(res);

            if (res.code == 1) {
                this.keyword = res.data.content
                this.fullscreenLoading = false
            } else {
                this.fullscreenLoading = false
            }

        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
        },
        uploadFn(response, file, fileList) {
            console.log(response, file, fileList);
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定移除 ${file.name}？`);
        },
        err(err, file, fileList) {
            console.log(err, file, fileList);
        },
        postData() {
            if (this.keyword != '') {
                this.aiNumShow = false
                this.fullscreenLoading = true
                this.curlPost('file/wrong_change', {
                    mobile: this.$user_info.mobile,
                    input_text: this.keyword,
                }).then(res => {
                    console.log(res);
                    if (res.data.code) {
                        this.fullscreenLoading = false
                        this.generatedContent = res.data.data.output_content

                        // 扣费
                        this.curlPost('/api/users/money_log/spend', {
                            ai_id: res.data.data.id,
                            amount: res.data.data.money,
                        }).then(res => {
                            if (res.data.code) {
                                this.getUserInfo()
                            }
                        })
                    }
                    this.fullscreenLoading = false
                })
            } else {
                this.aiNumShow = false
                return this.$message({
                    message: '禁止上传空文件!',
                    type: 'warning',
                    offset: 80
                });
            }
        }
    },
}
</script>

<style lang=scss scoped>
@import '../../scss/EnterpriseProfile.scss'
</style>